<template>
    <b-modal :ref="modalRef" :title="event ? 'Édition' : 'Création'"
             hide-footer @hidden="destroyModal" size="lg">
        <div class="text-center">
            <b-form @submit.prevent="submit">
                <div class="mb-3">
                    Dates
                    <two-dates-picker :date-debut="form.dateDebut" :date-fin="form.dateFin"
                                      @dateDebutChange="updateDateDebut" @dateFinChange="updateDateFin"/>
                </div>
                <b-col xl="6" offset-xl="3">
                    <b-form-group label="Nom">
                        <b-form-input v-model="form.nom" type="text" placeholder="Permanence"/>
                    </b-form-group>
                    <b-form-group label="URL">
                        <b-form-input v-model="form.url" type="url"/>
                    </b-form-group>
                    <b-btn type="submit" variant="success" class="m-3" :disabled="loading">
                        <font-awesome-icon icon="check"/>
                        Valider
                        <font-awesome-icon icon="sync" spin v-if="loading"/>
                    </b-btn>
                </b-col>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import {apiPath}  from '@/util/http';
    import modalMixin from '@/mixin/modalMixin';

    const TwoDatesPicker = () => import('@/components/TwoDatesPicker');

    export default {
        name: "AdminEditCalendarEventModal",
        components: {TwoDatesPicker},
        mixins: [modalMixin],
        props: {
            event: {
                type: Object,
                default: () => null
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'adminEditCalendarEventModal',
            uploadPicture: null,
            form: {
                dateDebut: null,
                dateFin: null,
                nom: null,
                url: null
            },
            loading: false
        }),
        methods: {
            updateDateDebut(val) {
                this.form.dateDebut = val;
            },
            updateDateFin(val) {
                this.form.dateFin = val;
            },
            submit() {
                if (!this.loading) {
                    this.loading = true;
                    this.axios
                        .post(
                            apiPath('admin_edit_calendar_event', this.event ? {event: this.event.id} : {}),
                            this.form,
                        )
                        .then(() => this.$toaster.success('Événement ' + (this.event ? 'modifié' : 'créé') + ' avec succès'))
                        .catch(() => this.$toaster.error("Impossible d'enregistrer l'événement"))
                        .finally(() => {
                            this.loading = false;
                            this.callback();
                            this.hideModal();
                        });
                }
            }
        },
        mounted() {
            if (this.event) {
                this.form = {...this.event};
            }
        }
    }
</script>
